<template>
  <div class="round">
    <div class="round-item">
      <div class="title">轮牌顺序</div>
      <div class="content-box">
        <div class="content-item">
          <el-button type="primary" size="mini" @click="onShowSort"
            >设置</el-button
          >
        </div>
      </div>
    </div>

    <div class="save-btn">
      <el-button type="primary" :disabled="saveDisabled" @click="saveRound"
        >保 存</el-button
      >
    </div>

    <RoundSortDialog
      ref="RoundSortDialog"
      @success="onChangeRoundSort"
    ></RoundSortDialog>
  </div>
</template>

<script>
import Setting from "@/api/setting.js";

import RoundSortDialog from "./components/round-sort-dialog.vue";

export default {
  components: { RoundSortDialog },

  data() {
    return {
      radio: 0,
      submitData: {
        Round_67: "",
        StoresGuid: "",
      },
      roundTypes: [],
      saveDisabled: false,
    };
  },

  mounted() {
    this.initRound();
  },

  methods: {
    // 初始化轮牌设置
    async initRound() {
      try {
        let { data } = await Setting.initRound();
        let arr = [];
        if (data.editData.Round_67 == "") {
          arr = await data.roundTypes;
        } else {
          await JSON.parse(data.editData.Round_67).forEach((item, index) => {
            for (let i = 0; i < data.roundTypes.length; i++) {
              if (item.Index == data.roundTypes[i].Index) {
                arr.push(data.roundTypes[i]);
                break;
              }
            }
          });
        }

        this.roundTypes = await arr;
        this.submitData = data.editData;
        // console.log(this.submitData, this.roundTypes);
      } catch (err) {
        console.log(err);
      }
    },

    // 保存轮牌设置
    async saveRound() {
      this.saveDisabled = false;
      try {
        let { errcode } = await Setting.saveRound(this.submitData);
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.initRound();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.saveDisabled = false;
      }
    },

    // 轮牌排序成功回调
    async onChangeRoundSort(event) {
      let arr = await event.map((item) => {
        return {
          Index: item.Index,
          Sort: item.Sort,
        };
      });
      this.roundTypes = event;
      this.submitData.Round_67 = await JSON.stringify(arr);
      console.log(this.submitData, this.roundTypes);
    },

    // 打开排序对话框
    onShowSort() {
      this.$refs.RoundSortDialog.onShowDialog(this.roundTypes);
    },
  },
};
</script>

<style lang="less" scoped>
.round {
  position: relative;
  height: 100%;

  &,
  .round-item {
    .flex-col;
    align-items: unset;
  }

  .round-item {
    padding: 0 10px;
    .title {
      padding: 10px 10px;
      margin-top: 10px;
      font-size: 14px;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
    }

    .content-box {
      .content-item {
        .flex-row;
        align-items: center;
        margin: 20px 10px;
        box-sizing: border-box;

        .group,
        .el-select {
          width: 400px;
          .el-textarea {
            margin-bottom: 10px;
          }
        }

        .name {
          margin-left: 5px;
        }

        .no-use {
          margin-left: 10px;
          color: tomato;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
      }

      .tips {
        margin-left: 10px;
        color: #999;
      }
    }
  }

  .save-btn {
    // .flex-row;
    // justify-content: center;
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 100%;
    margin: 20px 0;
    width: calc(- (50% / 2));
  }
}
</style>
